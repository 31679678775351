<template>
  <mdb-container>
    <mdb-col>
      <div class="mt-5 sub_content">
        <h3>기술 문의</h3>
      </div>
      <div class="card mx-xl-5 mt-3 p-3 mb-5">
        <div class="card-body">
          <form>
            <label for="title" class="grey-text">제목</label>
            <input type="text" id="title" class="form-control" v-model="title">
            <br>
            <label for="writer" class="grey-text">작성자</label>
            <input type="text" id="writer" class="form-control" v-model="writer">
            <br>
            <label for="email" class="grey-text">이메일</label>
            <input type="email" id="email" class="form-control" v-model="email" disabled>
            <br>
            <label class="grey-text">분야</label>
            <mdb-select
                class="sel_box"
                id="stField"
                color="primary"
                @getValue="getSelectValue"
                v-model="fields"
                placeholder="항목을 선택하세요."
            />
            <br>
            <label class="grey-text">상세내용</label>
            <textarea type="text" id="contents" class="form-control" rows="3" v-model="contents"></textarea>
            <br>
            <label class="grey-text">첨부파일</label>
            <mdb-file-input
                class="file-input-doc"
                btnTitle="문서파일 선택"
                @getValue="getFileInputValue"
                icon-right="upload"
                textFieldTitle="excel / pdf / hwp "
                sm
                accept="application/vnd.ms-excel, .pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .hwp"
            />
            <br>
            <label class="grey-text">이미지 등록</label>
            <mdb-file-input
                class="file-input-img"
                btnTitle="이미지파일 선택"
                @getValue="getImageFileInputValue"
                icon-right="upload"
                img
                :width="155"
                sm
                accept="image/gif, image/jpeg, image/png"
            />
            <div class="text-right mt-4">
              <mdb-btn outline="success" small @click="startUpload()" icon="pen">문의</mdb-btn>
            </div>
          </form>
        </div>
      </div>
    </mdb-col>
  </mdb-container>
</template>

<script>
import {mdbSelect, mdbContainer, mdbFileInput, mdbCol, mdbBtn} from 'mdbvue';
import firebase from "firebase/app";
import 'firebase/storage';
import "firebase/firestore";

export default {
  name: 'TechnicalForm',
  components: {
    mdbSelect,
    mdbContainer,
    mdbFileInput,
    mdbCol,
    mdbBtn,
  },
  data() {
    return {
      id: "",
      contents: "",
      title: "",
      email: this.$store.state.user_email,
      writer: "",
      fields: [
        {text: '드론', value: '드론'},
        {text: '웹', value: '웹'},
        {text: '앱', value: '앱'},
        {text: '펌웨어', value: '펌웨어'},
        {text: '하드웨어', value: '하드웨어'}
      ],
      sel_fields: "",
      fileList: [],
      imageFileList: [],
      image_url: '',
      files: {},
      file_url: '',
      file_name: '',
    };
  },
  methods: {
    getSelectValue(value) {
      const self = this;
      self.sel_fields = value;
    },
    getFileInputValue(val) {
      const self = this;
      self.fileList = val;
    },
    getImageFileInputValue(val) {
      const self = this;
      self.imageFileList = val;
    },
    onUploadFile() {
      const self = this;
      const storage = firebase.storage();

      const file = self.fileList[0];
      const storageRef = storage.ref();
      const savePath = storageRef.child('files/' + file.name);
      const upload = savePath.put(file)

      self.files = {};
      self.file_name = file.name;
      upload.on('state_changed', (snapshot) => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            console.error('실패사유는', error);
          },
          () => {
            upload.snapshot.ref.getDownloadURL().then((url) => {
              console.log('업로드된 경로는', url);
              self.file_url = url;
              self.files[self.file_name] = self.file_url

              if (self.imageFileList.length !== 0) {
                self.onImageUploadFile();
              } else {
                self.onSend();
              }
            });
          }
      );
    },

    onImageUploadFile() {
      const self = this;
      const storage = firebase.storage();

      const file = self.imageFileList[0];
      const storageRef = storage.ref();
      const savePath = storageRef.child('images/' + file.name);
      const upload = savePath.put(file)

      upload.on('state_changed', (snapshot) => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            console.error('실패사유는', error);
          },
          () => {
            upload.snapshot.ref.getDownloadURL().then((url) => {
              console.log('업로드된 경로는', url);
              self.image_url = url;
              self.onSend();
            });
          }
      );
    },
    startUpload() {
      const self = this;
      if (self.title === "" || self.contents === "" || self.email === "" || self.writer === "") {
        alert("모든 항목을 기입해 주세요")
        return;
      }

      if (self.fileList.length !== 0) {
        self.onUploadFile();
      } else {
        if (self.imageFileList.length !== 0) {
          self.onImageUploadFile();
        } else {
          self.onSend();
        }
      }

    },
    onSend() {
      const self = this;
      const db = firebase.firestore();

      const data = {
        title: self.title,
        writer: self.writer,
        email: self.email,
        fields: self.sel_fields,
        contents: self.contents,
        image_url: self.image_url,
        files: self.files,
        views: 0,
        uid: self.$store.state.firebase_uid,
        reg_date: firebase.firestore.Timestamp.fromDate(new Date()),
      }

      db.collection('technicalBoard')
          .add(data)
          .then(() => {
            alert('문의가 등록되었습니다.');
            this.$router.push('/technicalList').catch((err) => {
              console.log(err)
            })
          })
          .catch((err) => {
            console.log(err)
          })
    }
  }
}
</script>

<style scoped>
.sub_content {
  text-align: center;
}

.sub_content h3 {
  font-weight: bold;
}

.file-input-doc {
  margin-top: 0;
  margin-bottom: 0;
}

.file-input-img {
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  display: block;
}

.sel_box {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

</style>